import Api from '../../utils/api'
import Axios from '../../utils/request'

export default {
    namespace: 'true',
    state: {},
    mutations: {},
    actions: {
        // 获取升级包列表
        getOtaPage(context: any, params: any) {
            return Axios.post(Api.updateManager.post.getOtaPage, params)
        },
        // 添加升级包
        addOta(context: any, params: any) {
            return Axios.post(Api.updateManager.post.addOta, params)
        },
        // 升级包
        sendOTA(context: any, params: any) {
            return Axios.post(Api.updateManager.post.sendOTA, params)
        },
        // 查询下发记录
        getOtaRecordPage(context: any, params: any) {
            return Axios.post(Api.updateManager.post.getOtaRecordPage, params)
        },
        // 编辑升级包
        editOta(context: any, params: any) {
            return Axios.post(Api.updateManager.post.editOta, params)
        },
        // 删除升级包
        removeOta(context: any, params: any) {
            return Axios.post(Api.updateManager.post.removeOta, params)
        },
        // 获取升级包详情
        getOtaById(context: any, params: any) {
            return Axios.get(Api.updateManager.get.getOtaById, { params })
        },
    },
    getters: {},
}
