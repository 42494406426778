import { watch, ref, computed, provide } from 'vue';
import { useStore } from 'vuex';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import en from 'element-plus/dist/locale/en.mjs';
import langeZhCN from '@/lang/zhCn';
import langeEnUS from '@/lang/enUs';
export default {
  setup() {
    // 解决表格一直报错 <ResizeObserver loop completed with undelivered notifications.>的问题
    //-------start----------
    const debounce = (fn, delay) => {
      let timer = null;
      return (...args) => {
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          fn(...args);
        }, delay);
      };
    };
    const _ResizeObserver = window.ResizeObserver;
    window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
      constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
      }
    };
    //--------end---------
    const store = useStore();
    // 项目国际化语言切换
    const lang = ref(langeZhCN);
    // element组件国际化语言切换
    const locale = computed(() => {
      if (store.state.lang === 'zh-CN') return zhCn;
      if (store.state.lang === 'en-US') return en;
      return zhCn;
    });
    watch(() => store.state.lang, nv => {
      if (store.state.lang === 'zh-CN') {
        lang.value = langeZhCN;
      }
      if (store.state.lang === 'en-US') {
        lang.value = langeEnUS;
      }
    }, {
      immediate: true,
      deep: true
    });
    provide('lang', lang);
    return {
      locale
    };
  }
};