import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/theme-chalk/el-message.css'
import 'element-plus/theme-chalk/el-message-box.css'
import 'element-plus/theme-chalk/el-loading.css'
import * as echarts from 'echarts'
import vue3TreeOrg from 'vue3-tree-org'
import 'vue3-tree-org/lib/vue3-tree-org.css'
const app = createApp(App)
// 注册所有element图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
// import datav from '@jiaminghi/data-view'
// app.use(datav)
// import DataVVue3 from '@kjgl77/datav-vue3'
// app.use(DataVVue3)
app.config.globalProperties.$echarts = echarts
app.use(store)
app.use(router)
app.use(vue3TreeOrg)
app.mount('#app')
