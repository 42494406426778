/**
 * 对字典的处理，由于字典定义后基本改动的概率太小，所以，对于字典没有必要每次都去请求
 * 目前确保每次登录后就进行请求
 */

export const fetchDicts = (store: any) => {
    const dicts = ['DEVICE_CATEGORY', 'METER_PROP', 'METER_CHANNEL', 'TIME_UNIT']
    // const dicts = ['METER_PROP', 'METER_CHANNEL', 'TIME_UNIT']
    dicts.forEach(dict => {
        console.log(dict)
        if (dict == 'DEVICE_CATEGORY') {
            store.dispatch('getDict1')
        } else {
            setTimeout(() => {
                store.dispatch('getDict', { dictType: dict })
            }, 400)
        }
    })
}
