import Api from '../../utils/api'
import Axios from '../../utils/request'

export default {
    namespace: 'true',
    state: {},
    mutations: {},
    actions: {
        addCategory(context: any, params: any) {
            return Axios.post(Api.category.post.addCategory, params)
        },
        editCategory(context: any, params: any) {
            return Axios.post(Api.category.post.editCategory, params)
        },
        removeCategory(context: any, params: any) {
            return Axios.post(Api.category.post.removeCategory, params)
        },
        getCategoryList(context: any, params: any) {
            return Axios.post(Api.category.post.getCategoryList, params)
        },
    },
    getters: {},
}
