import Api from '../../utils/api'
import Axios from '../../utils/request'

export default {
    namespace: 'true',
    state: {},
    mutations: {},
    actions: {
        // 获取网关类型列表
        getGatewayTypePage(context: any, params: any) {
            return Axios.post(Api.gatewayManager.post.getGatewayTypePage, params)
        },
        // 修改网关类型状态
        changeGatewayTypeStatus(context: any, params: any) {
            return Axios.post(Api.gatewayManager.post.changeGatewayTypeStatus, params)
        },
        // 新增网关类型
        addGatewayType(context: any, params: any) {
            return Axios.post(Api.gatewayManager.post.addGatewayType, params)
        },
        // 编辑网关类型
        editGatewayType(context: any, params: any) {
            return Axios.post(Api.gatewayManager.post.editGatewayType, params)
        },
        // 删除网关类型
        removeGatewayType(context: any, params: any) {
            return Axios.post(Api.gatewayManager.post.removeGatewayType, params)
        },
        // 网关类型详情
        getGatewayTypeById(context: any, params: any) {
            return Axios.get(Api.gatewayManager.get.getGatewayTypeById, { params })
        },
        // 根据父id查询子类型
        getGatewayTypeListByParentId(context: any, params: any) {
            return Axios.get(Api.gatewayManager.get.getGatewayTypeListByParentId, { params })
        },
        // 获取网关组列表
        getGatewayGroupPage(context: any, params: any) {
            return Axios.post(Api.gatewayManager.post.getGatewayGroupPage, params)
        },
        // 新增网关组
        addGatewayGroup(context: any, params: any) {
            return Axios.post(Api.gatewayManager.post.addGatewayGroup, params)
        },
        // 编辑网关组
        editGatewayGroup(context: any, params: any) {
            return Axios.post(Api.gatewayManager.post.editGatewayGroup, params)
        },
        // 删除网关组
        removeGatewayGroup(context: any, params: any) {
            return Axios.post(Api.gatewayManager.post.removeGatewayGroup, params)
        },
        // 网关组详情
        getGatewayGroupById(context: any, params: any) {
            return Axios.get(Api.gatewayManager.get.getGatewayGroupById, { params })
        },
        // 获取网关组列表
        getGatewayPage(context: any, params: any) {
            return Axios.post(Api.gatewayManager.post.getGatewayPage, params)
        },
        // 新增网关组
        addGateway(context: any, params: any) {
            return Axios.post(Api.gatewayManager.post.addGateway, params)
        },
        // 编辑网关组
        editGateway(context: any, params: any) {
            return Axios.post(Api.gatewayManager.post.editGateway, params)
        },
        // 删除网关组
        removeGateway(context: any, params: any) {
            return Axios.post(Api.gatewayManager.post.removeGateway, params)
        },
        // 网关组详情
        getGatewayById(context: any, params: any) {
            return Axios.get(Api.gatewayManager.get.getGatewayById, { params })
        },
    },
    getters: {},
}
