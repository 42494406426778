import Api from '../../utils/api'
import Axios from '../../utils/request'

export default {
    namespace: 'true',
    state: {},
    mutations: {},
    actions: {
        getAssetPage(context: any, params: any) {
            return Axios.post(Api.assetManage.post.getAssetPage, params)
        },
        getTypePage(context: any, params: any) {
            return Axios.post(Api.assetManage.post.getTypePage, params)
        },
        nodeGetTypePage(context: any, params: any) {
            return Axios.post(Api.assetManage.post.nodeGetTypePage, params)
        },
        getAddAsset(context: any, params: any) {
            return Axios.post(Api.assetManage.post.addAsset, params)
        },
        editAsset(context: any, params: any) {
            return Axios.post(Api.assetManage.post.editAsset, params)
        },
        removeAsset(context: any, params: any) {
            return Axios.post(Api.assetManage.post.removeAsset, params)
        },
        changeUseStatus(context: any, params: any) {
            return Axios.post(Api.assetManage.post.changeUseStatus, params)
        },
        getTenantPageList(context: any, params: any) {
            return Axios.post(Api.assetManage.post.getTenantPage, params)
        },
        changeTenant(context: any, params: any) {
            return Axios.post(Api.assetManage.post.changeTenant, params)
        },
        assetImport(context: any, params: any) {
            return Axios.post(Api.assetManage.post.assetImport, params)
        },
        claimAsset(context: any, params: any) {
            return Axios.post(Api.assetManage.post.claimAsset, params)
        },
        getSubAccountPage2(context: any, params: any) {
            return Axios.post(Api.assetManage.post.getSubAccountPage2, params)
        },
        returnAsset(context: any, params: any) {
            return Axios.post(Api.assetManage.post.returnAsset, params)
        },
    },
    getters: {},
}
