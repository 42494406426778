import Api from '../../utils/api'
import Axios from '../../utils/request'

export default {
    namespace: 'true',
    state: {},
    mutations: {},
    actions: {
        // 获取项目类型列表
        getProjectTypePage(context: any, params: any) {
            return Axios.post(Api.projectManager.post.getProjectTypePage, params)
        },
        // 修改项目类型状态
        changeProjectTypeStatus(context: any, params: any) {
            return Axios.post(Api.projectManager.post.changeProjectTypeStatus, params)
        },
        // 删除项目类型
        removeProjectType(context: any, params: any) {
            return Axios.post(Api.projectManager.post.removeProjectType, params)
        },
        //删除项目
        removeProject(context: any, params: any) {
            return Axios.post(Api.projectManager.post.removeProject, params)
        },
        //导出
        download(context: any, params: any) {
            return Axios.post(Api.projectManager.post.download, params)
        },
        // 新增项目类型
        addProjectType(context: any, params: any) {
            return Axios.post(Api.projectManager.post.addProjectType, params)
        },
        // 编辑项目类型
        editProjectType(context: any, params: any) {
            return Axios.post(Api.projectManager.post.editProjectType, params)
        },
        // 获取项目类型详情
        getProjectTypeById(context: any, params: any) {
            return Axios.get(Api.projectManager.get.getProjectTypeById, { params })
        },
        // 获取项目列表
        getProjectPage(context: any, params: any) {
            return Axios.post(Api.projectManager.post.getProjectPage, params)
        },
        // 获取告警类型
        getAlarmTypes(context: any, params: any) {
            return Axios.get(Api.projectManager.get.getAlarmTypes, params)
        },
        // 获取传感器
        getLowPowerDevices(context: any, params: any) {
            return Axios.post(Api.projectManager.post.getLowPowerDevices, params)
        },
        // 分页查询项目树形结构
        getNodeTreePage(context: any, params: any) {
            return Axios.post(Api.projectManager.post.getNodeTreePage, params)
        },
        // 获取父项目列表
        getRootParentProject(context: any, params: any) {
            return Axios.get(Api.projectManager.get.getRootParentProject, params)
        },
        // 修改项目状态
        changeProjectStatus(context: any, params: any) {
            return Axios.post(Api.projectManager.post.changeProjectStatus, params)
        },
        // 新增项目
        addProject(context: any, params: any) {
            return Axios.post(Api.projectManager.post.addProject, params)
        },
        // 编辑项目
        editProject(context: any, params: any) {
            return Axios.post(Api.projectManager.post.editProject, params)
        },
        // 查询位置分组
        getGroupPage(context: any, params: any) {
            return Axios.post(Api.projectManager.post.getGroupPage, params)
        },
        // 编辑位置分组
        editTagGrop(context: any, params: any) {
            return Axios.post(Api.projectManager.post.editTagGrop, params)
        },
        // 删除位置分组
        removeTagGrop(context: any, params: any) {
            return Axios.post(Api.projectManager.post.removeTagGrop, params)
        },
        // 新增位置分组
        addTagGrop(context: any, params: any) {
            return Axios.post(Api.projectManager.post.addTagGrop, params)
        },
        // 添加标签
        addTag(context: any, params: any) {
            return Axios.post(Api.projectManager.post.addTag, params)
        },
        // 编辑标签
        editTag(context: any, params: any) {
            return Axios.post(Api.projectManager.post.editTag, params)
        },
        // 删除位置分组
        removeTag(context: any, params: any) {
            return Axios.post(Api.projectManager.post.removeTag, params)
        },
        // 获取项目详情
        getProjectById(context: any, params: any) {
            return Axios.get(Api.projectManager.get.getProjectById, { params })
        },
        // 获取项目标签
        getProjectTags(context: any, params: any) {
            return Axios.get(Api.projectManager.get.getProjectTags, { params })
        },
        // 获取项目标签
        getAssetsRecords(context: any, params: any) {
            return Axios.post(Api.projectManager.get.getAssetsRecords, params)
        },
    },
    getters: {},
}
