import Api from '../../utils/api'
import Axios from '../../utils/request'

export default {
    namespace: 'true',
    state: {},
    mutations: {},
    actions: {
        // 获取列表
        getPage(context: any, params: any) {
            return Axios.post(Api.modelManger.post.getPage, params)
        },
        add(context: any, params: any) {
            return Axios.post(Api.modelManger.post.add, params)
        },
        getById(context: any, params: any) {
            return Axios.get(Api.modelManger.get.getById, { params })
        },
        edit(context: any, params: any) {
            return Axios.post(Api.modelManger.post.edit, params)
        },
        getModelService(context: any, params: any) {
            return Axios.get(Api.modelManger.get.getModelService, { params })
        },
        modelGetById(context: any, params: any) {
            return Axios.get(Api.modelManger.get.modelGetById, { params })
        },
        addModelService(context: any, params: any) {
            return Axios.post(Api.modelManger.post.addModelService, params)
        },
        editModelService(context: any, params: any) {
            return Axios.post(Api.modelManger.post.editModelService, params)
        },
        modeldelete(context: any, params: any) {
            return Axios.post(Api.modelManger.post.modeldelete, params)
        },
        modelImport(context: any, params: any) {
            return Axios.post(Api.modelManger.post.modelImport, params)
        },
    },
    getters: {},
}
