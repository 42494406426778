import Api from '../../utils/api'
import Axios from '../../utils/request'

export default {
    namespace: 'true',
    state: {},
    mutations: {},
    actions: {
        getFeedbackPage(context: any, params: any) {
            return Axios.post(Api.afterSales.post.getFeedbackPage, params)
        },
        editFeedback(context: any, params: any) {
            return Axios.post(Api.afterSales.post.editFeedback, params)
        },
    },
    getters: {},
}
