import Api from '../../utils/api'
import Axios from '../../utils/request'

export default {
    namespace: 'true',
    state: {},
    mutations: {},
    actions: {
        // 获取任务策略列表
        getTaskPage(context: any, params: any) {
            return Axios.post(Api.intelligentControl.post.getTaskPage, params)
        },
        // 新增任务策略
        addTask(context: any, params: any) {
            return Axios.post(Api.intelligentControl.post.addTask, params)
        },
        // 删除任务策略
        removeTask(context: any, params: any) {
            return Axios.post(Api.intelligentControl.post.removeTask, params)
        },
        // 编辑任务策略
        editTask(context: any, params: any) {
            return Axios.post(Api.intelligentControl.post.editTask, params)
        },
        // 获取任务详情
        getTaskById(context: any, params: any) {
            return Axios.get(Api.intelligentControl.get.getTaskById, { params })
        },
        // 获取任务属性内容
        getTaskContent(context: any, params: any) {
            return Axios.get(Api.intelligentControl.get.getTaskContent, { params })
        },
        // 获取任务控制列表
        getControlTaskPage(context: any, params: any) {
            return Axios.post(Api.intelligentControl.post.getControlTaskPage, params)
        },
        // 新增任务控制
        addControlTask(context: any, params: any) {
            return Axios.post(Api.intelligentControl.post.addControlTask, params)
        },
        // 删除任务控制
        removeControlTask(context: any, params: any) {
            return Axios.post(Api.intelligentControl.post.removeControlTask, params)
        },
        // 编辑任务控制
        editControlTask(context: any, params: any) {
            return Axios.post(Api.intelligentControl.post.editControlTask, params)
        },
        // 获取任务控制详情
        getControlTaskById(context: any, params: any) {
            return Axios.get(Api.intelligentControl.get.getControlTaskById, { params })
        },
        // 下发任务控制
        executeControlTask(context: any, params: any) {
            return Axios.post(Api.intelligentControl.post.executeControlTask, params)
        },
        //获取服务列表
        getService(context: any, params: any) {
            return Axios.get(Api.intelligentControl.get.getService, { params })
        },
        getAlarmService(context: any, params: any) {
            return Axios.get(Api.intelligentControl.get.getAlarmService, { params })
        },
        //编辑型号能耗规则
        editEnergyAlgorithm(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.editEnergyAlgorithm, params)
        },
    },
    getters: {},
}
