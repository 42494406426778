import axios from 'axios'
import { ElMessage } from 'element-plus'
import router from '@/router/index'

const Axios = axios.create({
    baseURL: '/iotApi',
    timeout: 30000,
})

// 请求拦截器
Axios.interceptors.request.use(config => {
    config.headers['Authorization'] = localStorage.getItem('ACCESS_TOKEN') || ''
    config.headers['Accept-Language'] = localStorage.getItem('lang') || 'zh-CN'
    return config
})

// 响应拦截器
Axios.interceptors.response.use(
    response => {
        // 下载请求
        const headers = response.headers
        if (headers['content-type']?.toString().includes('application/vnd.ms-excel')) {
            return response
        }
        const data = response.data
        if (data.code === '0') {
            return data
        } else {
            // 报错信息
            ElMessage.error(data.message || '系统错误，请稍后尝试')
        }
        return Promise.reject(data)
    },
    error => {
        const { response, message } = error
        if (response.status === 401) {
            ElMessage.error('登录失效，请重新登陆')
            localStorage.removeItem('ACCESS_TOKEN')
            return router.push({ name: 'Login' })
        } else {
            ElMessage.error(message || '系统错误，请稍后尝试')
        }
        return Promise.reject(error)
    },
)

export default Axios
