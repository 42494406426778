import Api from '../../utils/api'
import Axios from '../../utils/request'

export default {
    namespace: 'true',
    state: {},
    mutations: {},
    actions: {
        // 获取告警策略列表
        getAlarmPage(context: any, params: any) {
            return Axios.post(Api.intelligentAlarm.post.getAlarmPage, params)
        },
        // 新增告警策略
        addAlarm(context: any, params: any) {
            return Axios.post(Api.intelligentAlarm.post.addAlarm, params)
        },
        // 删除告警策略
        removeAlarm(context: any, params: any) {
            return Axios.post(Api.intelligentAlarm.post.removeAlarm, params)
        },
        // 编辑告警策略
        editAlarm(context: any, params: any) {
            return Axios.post(Api.intelligentAlarm.post.editAlarm, params)
        },
        // 获取告警策略详情
        getAlarmById(context: any, params: any) {
            return Axios.get(Api.intelligentAlarm.get.getAlarmById, { params })
        },
        // 获取告警属性内容
        getAlarmContent(context: any, params: any) {
            return Axios.get(Api.intelligentAlarm.get.getAlarmContent, { params })
        },
        // 下发告警策略
        executeAlarm(context: any, params: any) {
            return Axios.post(Api.intelligentAlarm.post.executeAlarm, params)
        },
        // 获取告警事件列表
        getAlarmRecordPage(context: any, params: any) {
            return Axios.post(Api.intelligentAlarm.post.getAlarmRecordPage, params)
        },
        // 获取告警状态
        changeAlarmRecordStatus(context: any, params: any) {
            return Axios.post(Api.intelligentAlarm.post.changeAlarmRecordStatus, params)
        },
        repairEvent(context: any, params: any) {
            return Axios.post(Api.intelligentAlarm.post.repairEvent, params)
        },
        getRepairRecord(context: any, params: any) {
            return Axios.get(Api.intelligentAlarm.get.getRepairRecord, { params })
        },
    },
    getters: {},
}
