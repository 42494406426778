// Copyright 2023 Peter Chen
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

/**
 * 生成范围内的随机数
 * @param min 最小值
 * @param max 最大值
 * @param isFloat 是否包含小数点
 * @returns number
 */
export const random = (min = 0, max = 100, isFloat = false) => {
    const array = new Uint32Array(1)
    const maxUint = 0xffffffff
    const randomNumber = crypto.getRandomValues(array)[0] / maxUint
    const randomRangeValue = (max - min + 1) * randomNumber + min
    return isFloat ? randomRangeValue : Math.floor(randomRangeValue)
}

/**
 * 计算最大运行轨道
 * @param x 轨道坐标x
 * @param y 轨道坐标y
 * @returns 最大的运行轨道坐标
 */
export const maxOrbit = (x: number, y: number) => {
    const max = Math.max(x, y)
    const diameter = Math.round(Math.sqrt(max * max + max * max))
    return diameter / 2
}

/**
 * 从数组当中找到满足条件的元素
 * @param array 数组
 * @param value 匹配条件值
 * @param key 匹配元素key
 * @returns 返回元素
 */
export const findElementInArray = (array: any[], value: any, key: string): any => {
    return array.find(item => item[key] === value)
}

/**
 * 获取最近24小时的开始结束时间
 * @returns 开始，结束时间
 */
export const getRecentDayTimes = () => {
    // 获取当前时间
    const now = new Date()
    // 获取最近 24 小时的开始时间
    const yesterday = new Date(now)
    yesterday.setDate(now.getDate() - 1)

    return {
        start: yesterday,
        end: now,
    }
}

export const downloadFile = (blob: any, fileName: string) => {
    const elink = document.createElement('a')
    elink.download = fileName
    elink.style.display = 'none'
    elink.href = URL.createObjectURL(blob)
    document.body.appendChild(elink)
    elink.click()
    URL.revokeObjectURL(elink.href) // 释放URL 对象
    document.body.removeChild(elink)
}
