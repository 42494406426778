import store from '@/store/index'
// 递归处理路由配置，提取权限值.
export const handlePermission = (list: any[], authCodes: any[]): any[] => {
    const res: any[] = []
    list.forEach((item: any) => {
        if (authCodes.includes(item.meta.permissionCode)) {
            const data: any = { name: item.meta.title, enName: item.meta.enTitle, code: item.meta.permissionCode }
            if (item.meta.operateCodeList) {
                data.operateCodeList = item.meta.operateCodeList
            }
            res.push(data)
            if (item.children) {
                data.children = handlePermission(item.children, authCodes)
            }
        }
    })
    return res
}

// 根据用户权限，递归处理路由配置，生成用户菜单
export const handleMenu = (list: any, menusAuthCodes: any[]): any[] => {
    const res: any[] = []
    list.forEach((item: any) => {
        const permissionCode: string = item.meta.permissionCode
        if (!item.meta.hidden && (menusAuthCodes.includes(permissionCode) || !permissionCode)) {
            res.push(item)
            if (item.children) {
                item.children = handleMenu(item.children, menusAuthCodes)
            }
        }
    })
    return res
}

// 判断用户是否有按钮权限
export const isOpAuth = (opAuthCode: string): boolean => {
    const opAuthCodes = store.state.userInfo.opAuthCodes
    return opAuthCodes.includes(opAuthCode)
}

// 判断用户是否有菜单权限
export const isMenuAuth = (menusAuthCode: string): boolean => {
    const menusAuthCodes = store.state.userInfo.menusAuthCodes
    return menusAuthCodes.includes(menusAuthCode)
}
// 验证是否合法的手机号
export const phoneNumberValid = (phoneNumber: string) => {
    return /^1\d{10}$/.test(phoneNumber)
}

// 判断是否只有字母、数字、下划线
export const validAlphabets = (value: string) => {
    const reg = /^[A-Za-z0-9_]+$/
    return reg.test(value)
}

// 判断是否包含中文、中午字符、空格
export const validCharacter = (value: string) => {
    const reg = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g
    return reg.test(value) || /\s/g.test(value)
}

// 判断是否包特殊字符
export const validSpecial = (value: string) => {
    const reg = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？」「]")
    return reg.test(value)
}
